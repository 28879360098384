import Vue from 'vue'
import Vuex from 'vuex'
import firebase from 'firebase/app'
import 'firebase/firestore'
import {DeviceUUID} from 'device-uuid'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: '',
    uuid: '',
    loggedIn: false,
    versionMismatch: false,
    version: '0.8.3',
    doj: null,
    meta: {},
    payment: {loading: true},
    testpayment: {loading: true},
    alert: {alert: false},
    test: [],
    attempt: 0,
    testResult1: null,
    testResult2: null,
    testResult3: null,
    testNo: '',
    user: '', 
    branch: '',
    userData: {},
    roll: '',
    connected: true
  },
  mutations: {
    SET_UUID (state, payload) {
      state.uuid = payload
    },
    SET_CONNECTION (state, payload) {
      state.connected = payload
    },
    SET_DOJ (state, payload) {
      state.doj = payload
    },
    SET_LOGGED_IN(state, payload) {
      state.loggedIn = payload;
    },
    setBranch (state, payload) {
      state.branch = payload
    },
    setMeta (state, payload) {
      state.meta = payload
    },
    setAttempt (state, payload) {
      state.attempt = payload
    },
    setAlert (state, payload) {
      state.alert = payload
    },
    setTest (state, payload) {
      state.test = payload
    },
    setTestResult1 (state, payload) {
      state.testResult1 = payload
    },
    setTestResult2 (state, payload) {
      state.testResult2 = payload
    },
    setTestResult3 (state, payload) {
      state.testResult3 = payload
    },
    setTestNo (state, payload) {
      state.testNo = payload
    },
    setUser (state, payload) {
      state.user = payload
    },
    setUserData (state, payload) {
      state.userData = payload
    },
    setRoll (state, payload) {
      state.roll = payload
    },
    setPaymentDetails (state, payload) {
      state.payment = payload
    },
    setTestPaymentDetails (state, payload) {
      state.testpayment = payload
    },
    setVersionMismatch (state, payload) {
      state.versionMismatch = payload
    },
    setToken (state, payload) {
      state.token = payload
    }
  },
  actions: {

    setUUID ({commit}) {
      var uuid = new DeviceUUID().get()
      // firebase.database().ref('uuids/'+payload).set({uuid: uuid})
      // .then(() => {
      //   setInterval (() => dispatch('checkUUID', payload), 60000)
      // })
      commit('SET_UUID', uuid)
    },

    checkUUID ({getters}, payload) {
      var loadedUUID = getters.loadedUUID
      firebase.database().ref('uuids/'+payload).once('value')
      .then((data) => {
        if(!data.val() || data.val().uuid === '') {
          firebase.database().ref('uuids/'+payload).set({uuid: loadedUUID})
        }
      })
    },

    setFireToken ({getters, dispatch, commit}, payload) {
      commit('setToken', payload)
      var roll = getters.loadedRoll
      var loggedIn = getters.isLoggedIn
      if (!loggedIn) {
        setTimeout (() => dispatch('setFireToken', payload), 10000)
      }
      else {
        firebase.database().ref('tokens/'+roll).set({token: payload})
      }
    },

    setStatus ({commit}, payload) {
      var connectionRef = firebase.database().ref('students/'+payload)
      firebase.database().ref('.info/connected').on('value', (snapshot) => {
        commit('SET_CONNECTION', snapshot.val())
        if (snapshot.val() == true) {
          connectionRef.update({online: true, last_login: firebase.database.ServerValue.TIMESTAMP})
        }
        connectionRef.onDisconnect().update({online: false})
      })
    },
    checkVersion ({commit, getters}) {
      firebase.database().ref('version').once('value')
      .then((data) => {
        var loaded = getters.loadedVersion
        if(data.val() != loaded) {
          commit('setVersionMismatch', true)
        }
      })
    },
    checkDOJ ({commit}, payload) {
      firebase.database().ref('students/'+payload+'/doj').once('value')
      .then((data) => {
          commit('SET_DOJ', data.val())
      })
    },
    checkPayment ({commit}, payload) {
      firebase.database().ref('payment/'+payload).once('value')
      .then((data) => {
        if(data.val()){
          commit('setPaymentDetails', data.val())
        } else {
          commit('setPaymentDetails', {status: 'unpaid', expires: 0})
        }
      })
    },

    checkTestPayment ({commit}, payload) {
      firebase.database().ref('payment/test/'+payload).once('value')
      .then((data) => {
        if(data.val()){
          commit('setTestPaymentDetails', data.val())
        } else {
          commit('setTestPaymentDetails', {status: 'unpaid', expires: 0})
        }
      })
    },

    loadTestResults ({commit, getters}) {
      var roll = getters.loadedRoll
      firebase.database().ref('test1/results/'+getters.loadedBranch+'/'+roll).once('value')
      .then((data) => {
        if(data.val()) {
          commit('setTestResult1', data.val())
        }
      })
      firebase.database().ref('test2/results/'+getters.loadedBranch+'/'+roll).once('value')
      .then((data) => {
        if(data.val()) {
          commit('setTestResult2', data.val())
        }
      })
      firebase.database().ref('test3/results/'+getters.loadedBranch+'/'+roll).once('value')
      .then((data) => {
        if(data.val()) {
          commit('setTestResult3', data.val())
        }
      })
    },
    setBranch ({commit}, payload) {
      commit('setBranch', payload)
    },
    setUser ({commit}, payload) {
      commit('setUser', payload)
    },
    setUserData ({commit}, payload) {
      commit('setUserData', payload)
    },
    setRoll ({commit}, payload) {
      commit('setRoll', payload)
    },
    setMeta ({commit}, payload) {
      commit('setMeta', payload)
    },
    setAttempt ({commit}, payload) {
      commit('setAttempt', payload)
    },
    loadUserData ({commit, getters}) {
      firebase.firestore().collection('students').doc(getters.loadedRoll).get()
      .then((data) => {
        commit('setUserData', data.data())
      })
    },
    loadTest ({commit}, payload) {      
      commit('setTest', payload)
    },
    setTestNo ({commit}, payload) {      
      commit('setTestNo', payload)
    },
    createAlert ({commit}, payload) {
      const data = {
          alert: true,
          type: payload.type,
          message: payload.message
      }
      commit('setAlert', data)
      setTimeout(() => {
        commit('setAlert', {alert: false})
      }, 3000);
    },  
  },
  getters: {
    theDOJ (state) {
      return state.doj
    },
    loadedVersion (state) {
      return state.version
    },
    isLoggedIn (state) {
      return state.loggedIn
    },
    isVersionMismatch (state) {
      return state.versionMismatch
    },
    loadedPaymentDetails (state) {
      return state.payment
    },
    loadedTestPaymentDetails (state) {
      return state.testpayment
    },
    loadedBranch (state) {
      return state.branch
    },
    loadedMeta (state) {
      return state.meta
    },
    loadedAttempt (state) {
      return state.attempt
    },

    loadedAlert (state) {
      return state.alert
    },
    loadedTest (state) {
      return state.test
    },
    loadedTestResults (state) {
      return {r1: state.testResult1, r2: state.testResult2, r3: state.testResult3}
    },
    loadedTestNo (state) {
      return state.testNo
    },
    loadedUser (state) {
      return state.user
    },
    loadedRoll (state) {
      return state.roll
    },
    loadedUserData (state) {
      return state.userData
    },
    loadedToken (state) {
      return state.token
    },
    loadedUUID (state) {
      return state.uuid
    },
  }
})
