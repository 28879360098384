import Vue from 'vue'
import Router from 'vue-router'
// import Home from './views/Home.vue'
// import Test from './components/Test.vue'
// import Questions from './components/Questions.vue'
import Live from './components/Live.vue'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    // {
    //   path: '/',
    //   name: 'home',
    //   component: Home
    // },
    // {
    //   path: '/test',
    //   name: 'test',
    //   component: Test
    // },
    // {
    //   path: '/question',
    //   name: 'question',
    //   component: Questions
    // },
    // {
    //   path: '/material',
    //   name: 'material',
    //   component: Materials
    // },
    // {
    //   path: '/testpage',
    //   name: 'testpage',
    //   component: () => import('./views/TestPage.vue')
    // },
    {
      path: '/about',
      name: 'about',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ './views/About.vue')
    },
    {
      path: '/',
      //name: 'Live',
      component: Live
    },
    {
      path: '/silive',
      component: () => import(/* webpackChunkName: "about" */ './components/SILive.vue')
    },
    {
      path: '/aolive',
      component: () => import(/* webpackChunkName: "about" */ './components/AOLive.vue')
    },
    {
      path: '/t1live',
      component: () => import(/* webpackChunkName: "about" */ './components/T1Live.vue')
    },
    {
      path: '/t2live',
      component: () => import(/* webpackChunkName: "about" */ './components/T2Live.vue')
    },
    {
      path: '/solive',
      component: () => import(/* webpackChunkName: "about" */ './components/SOLive.vue')
    },
  ]
})
